import React from 'react';

import '../styles/main.css';
import '../styles/main.scss';

// import Banner from './Banner';
import Header from './Header';
import Footer from './Footer';
// import SEO from './SEO';
// import RotateDevice from '../components/RotateDevice';

function Layout(props) {
  const {
    children,
    location,
    fullPage,
    shop,
    potockAnimation,
    isBackgroundFixed,
  } = props;

  // const fullPages = ['/', '/zespol/', '/zespol'];

  // const fullPage = fullPages.find(f => {
  //   return f === location.pathname;
  // });

  return (
    <React.Fragment>
      {/* <SEO /> */}
      {/* <LocaleProvider locale={locale} location={location}> */}
      {/* <Banner /> */}

      <Header {...location} shop={shop} />

      <div
        className=""
        style={{
          minHeight: 'calc(100vh - 56px)',
          position: isBackgroundFixed ? 'fixed' : 'inherit',
          width: '100%',
        }}
      >
        {children}
      </div>

      <Footer fixed={fullPage} shop={shop} />
      {/* </LocaleProvider> */}
      <div
        id="music-transition-overlay"
        className="w-full h-screen absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundColor: 'black',
          display: potockAnimation ? '' : 'none',
        }}
      >
        <div className="bars overflow-hidden w-full absolute left-0 right-0 top-0 bottom-0">
          {new Array(50).fill(0).map((b, i) => {
            return <div key={i} className="bar"></div>;
          })}
        </div>
        <img
          className="absolute w-3/4"
          style={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          src={'/logo-black.svg'}
          alt="Logo Potock"
        />
      </div>
    </React.Fragment>
  );
}

export default Layout;
