import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
const RotateDeviceWrapper = styled.div`
  display: none;

  @media (max-width: 768px) and (orientation: portrait) {
    display: block;
    z-index: 30;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
    bottom: 10vh;
    color: rgba(255, 255, 255, 0.3);
    font-size: 3rem;
    animation-name: rotateDevice;
    animation-duration: 3s;
    animation-iteration-count: 5;
    animation-timing-function: linear;
    opacity: 0;
    transform-origin: 50% 50%;
    line-height: 1rem;

    svg {
      margin: auto;
    }
  }

  @keyframes rotateDevice {
    0% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%);
    }

    10% {
      opacity: 1;
      left: 50%;
      transform: translateY(-50%);
    }

    15% {
      opacity: 1;
      left: 50%;
      transform: translateY(-50%);
    }

    40% {
      left: 50%;
      transform: rotate(-90deg) translateY(-50%);
      opacity: 1;
    }

    80% {
      left: 50%;
      opacity: 1;
      transform: rotate(-90deg) translateY(-50%);
    }

    90% {
      left: 50%;
      opacity: 0;
      transform: rotate(-90deg) translateY(-50%);
    }

    100% {
      left: 50%;
      transform: translateY(-50%);
      opacity: 0;
    }
  }
`;

const RotateDevice = () => {
  return (
    <RotateDeviceWrapper className="rotate-device-animation opacity-0">
      <FontAwesomeIcon icon={faMobileAlt} />
    </RotateDeviceWrapper>
  );
};

export default RotateDevice;
