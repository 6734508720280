import React, { useEffect } from 'react';

import CookieConsent from 'react-cookie-consent';

import PotockLink from '../components/PotockLink';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faYoutube,
  faSpotify,
} from '@fortawesome/fontawesome-free-brands';

// Animations
import gsap from 'gsap';

// Main component
function Footer({ fixed }) {
  // Animate fadeing in header
  useEffect(() => {
    gsap.to('.footer', {
      opacity: 1,
      duration: 3,
      delay: 3,
      ease: 'power4.out',
    });
  }, []);

  return (
    <div
      className={
        'footer opacity-0 px-0 z-50 w-full bottom-0 ' + (fixed && ' fixed ')
      }
    >
      <div className="flex flex-col-reverse md:flex-row items-center md:justify-between border-gainsboro py-3">
        <p className="text-lightgray text-sm pl-6">
          <PotockLink to="/regulamin">regulamin</PotockLink> |{' '}
          <PotockLink to="/polityka-prywatnosci">
            polityka prywatności
          </PotockLink>{' '}
          | realizacja:{' '}
          <a
            href="https://kukla.tech"
            title="błyskawiczne strony internetowe, strony internetowe dla restauracji"
          >
            kukla.tech
          </a>
        </p>

        <ul className="inline-flex">
          <li className="my-1">
            <a
              href="https://www.facebook.com/p0t0ck"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-primary text-sm p-3"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                style={{ fontSize: '1.2rem' }}
              />
            </a>
          </li>
          <li className="my-1">
            <a
              href="https://www.youtube.com/channel/UCxHCkdfKBnUBtAnJT6gwG6g"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-primary text-sm p-3"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                style={{ fontSize: '1.2rem' }}
              />
            </a>
          </li>
          <li className="my-1">
            <a
              href="https://open.spotify.com/artist/6OGxr27KhARDYWLfyQcF2D?si=cbhGYnHYQCaBqOz3rV4NjQ"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-primary text-sm p-3"
            >
              <FontAwesomeIcon
                icon={faSpotify}
                style={{ fontSize: '1.2rem' }}
              />
            </a>
          </li>
        </ul>
      </div>

      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="OK"
        declineButtonText=""
        cookieName="gatsby-gdpr-google-analytics"
        buttonClasses="btn btn-primary"
        containerClasses="w-full fixed bt-0 mx-auto text-center flex justify-center py-3 text-white z-50"
        buttonClasses="btn bg-white text-black rounded ml-3 px-3"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          maxWidth: '500px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        acceptOnScroll={true}
        acceptOnScrollPercentage={10}
      >
        Na tej stronie używamy ciasteczek.{' '}
        <PotockLink to="/polityka-prywatnosci">Czytaj więcej</PotockLink>
      </CookieConsent>
    </div>
  );
}

export default Footer;
